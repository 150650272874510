export class PartnerFormModel {
  prename: string;
  lastname: string;
  email: string;
  password: string;
  password_repeat: string;
  name: string;
  company_size: string;
  industry: string;
  country_identifier: string;
  timezone: string;
  phone: number;
  language_identifier?: string;
  partnership_uuid?: string;
  utm_source?: string;
  token?: string;
  country_code?: string;
}
