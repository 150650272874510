export const IPLOOKUP_URL = 'https://ipinfo.io/json?token=e7e53bffbd5b01';

export const GTM_EVENT_CONSTANT = {
  STEP_1: 'register_step_1_success',
  STEP_1_VALIDATION_FAILED: 'register_step_1_validation_failed',
  STEP_2: 'register_step_2_success',
  STEP_2_VALIDATION_FAILED: 'register_step_2_validation_failed',
  STEP_2_SERVER_ERROR: 'register_step_2_server_error',
  STEP_3_VALIDATION_FAILED: 'register_step_3_validation_failed',
  STEP_3: 'register_step_3_success',
  STEP_3_SERVER_ERROR: 'register_step_3_server_error'
}

export const INDUSTRY_LIST = [
  { key: 'create_partner_component.industry_options.banken_finanzwesen', value: 'banking' },
  { key: 'create_partner_component.industry_options.behörden_verwaltungen', value: 'administrations' },
  { key: 'create_partner_component.industry_options.bildungseinrichtungen', value: 'education' },
  { key: 'create_partner_component.industry_options.dienstleistungsbranche', value: 'services' },
  { key: 'create_partner_component.industry_options.einzelhandel_ecommerce', value: 'ecommerce' },
  { key: 'create_partner_component.industry_options.eventmanagement_veranstaltungen', value: 'event_management' },
  { key: 'create_partner_component.industry_options.gesundheitswesen_medizin', value: 'health' },
  { key: 'create_partner_component.industry_options.immobilien_bauwesen', value: 'real_estate' },
  { key: 'create_partner_component.industry_options.personalwesen_recruiting', value: 'hr' },
  { key: 'create_partner_component.industry_options.rechtswesen_kanzleien', value: 'law' },
  { key: 'create_partner_component.industry_options.schönheit_wellness', value: 'beauty' },
  { key: 'create_partner_component.industry_options.sport_fitness', value: 'sport' },
  { key: 'create_partner_component.industry_options.unternehmensberatung', value: 'consulting' },
  { key: 'create_partner_component.industry_options.versicherungen_vorsorge', value: 'insurance' },
  { key: 'create_partner_component.industry_options.sonstigebranchen', value: 'other' }
];

export const COMPANY_SIZE = [
  { key: '1', value: '1' },
  { key: '2-10', value: '2-10' },
  { key: '11-50', value: '11-50' },
  { key: '51-250', value: '51-250' },
  { key: '251-1000', value: '251-1000' },
  { key: '1001-5000', value: '1001-5000' },
  { key: '5001-100000', value: '5001-20000' },
  { key: '0', value: '0' }
];

export const PREFERRED_COUNTRIES = ['ch', 'de', 'fr', 'gb', 'it', 'nl', 'fi', 'pl', 'es', 'se', 'at'];
