import { Injectable } from '@angular/core';
import { LoggerService } from '@services/logger.service';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  emitGTMEvent(eventName: string, params: any = null): void {
    LoggerService.log('Appointment booking step event ', eventName);

    // Google Tag Manager
    try {
      dataLayer.push({
        event: eventName,
        ...params
      });
      LoggerService.log(`[GOOGLE_TAG]:[${eventName}] event was fired.`, params);
    } catch (error) {
      LoggerService.warn('[GOOGLE_TAG]: Datalayer not defined.');
      LoggerService.error(error);
    }

    // Send event to parent window
    this.emitEventToParent(eventName, params);
  }

  emitEventToParent(eventName: string, params: any = null): void {
    try {
      window.parent.postMessage(
        {
          eventName: eventName,
          bookingData: params,
        },
        '*'
      );
      LoggerService.log(`[EMIT_TO_PARNT_WINDOW]:[${eventName}] event was fired.`, params);
    } catch (error) {
      LoggerService.warn('[EMIT_TO_PARNT_WINDOW]: Adobe event error handler.');
      LoggerService.error(error);
    }
  }
}
