<div class="calio-pc-container container-fluid">
  <div class="calio-pc-main-container ">
    @if (activeStep !== 3) {
      <div class="stepper-container d-flex align-items-center left-container pb-0 mt-3">
        <div class="progress" [attr.data-percentage]="activeStep === 1 ? 50 : 100">
          <span class="progress-left">
            <span class="progress-bar"></span>
          </span>
          <span class="progress-right">
            <span class="progress-bar"></span>
          </span>
        </div>
        <div class="ms-3 stepper-font">
          {{ 'create_partner_component.step_count' | translate : {'step': activeStep ,'totalSteps': 2} }}
        </div>
      </div>
    }

    <div class="left-container col-12 col-md-1">
      @defer (on viewport) {
        @if (activeStep === 1) {
          <div class="calio-pc-heading">
            {{ 'create_partner_component.heading_1' | translate }}
            <br>
            {{ 'create_partner_component.heading_2' | translate }}
          </div>
          <div class="calio-pc-subtitle">
            {{ 'create_partner_component.subtitle' | translate }}
          </div>
        } @else if (activeStep === 2) {
          <div class="calio-pc-heading">
            {{ 'create_partner_component.step_2_heading' | translate }}
          </div>
          <div class="calio-pc-subtitle">
            {{ 'create_partner_component.step_2_subtitle' | translate }}
          </div>
        }
      } @placeholder {
        <div></div>
      }

      @if (warningDescriptionText) {
        <div class="card bg-white shadow rounded card-box mb-5">
          <div class="card-body">
            <div class="d-flex">
              <div class="text-center icon-block">
                <div class="notification-icon">
                  <img src="/assets/images/notifications/warning_ico.svg" />
                </div>
              </div>
              <div class="content-block">
                <div class="notification-title text-warning">
                  {{ 'error_messages.' + warningTitleText | translate }}
                </div>
                <div class="notification-description pt-2">
                  {{ 'error_messages.' + warningDescriptionText | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if (errorText) {
        <div class="card bg-white shadow rounded card-box mb-5">
          <div class="card-body">
            <div class="d-flex">
              <div class="text-center icon-block">
                <div class="notification-icon">
                  <img src="/assets/images/notifications/problem_ico.svg" />
                </div>
              </div>
              <div class="content-block">
                <div class="notification-title text-problem">
                  {{ 'error_messages.error_title' | translate }}
                </div>
                <div class="notification-description pt-2">
                  {{ 'error_messages.' + errorText | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if (activeStep === 1 && uiMode !== 'reseller') {
        @defer {
          <app-email-step
            [emailFormError]="emailFormError"
            (onNextEvent)="saveEmail($event)"
          />
        }
      } @else if (activeStep === 2 && uiMode !== 'reseller') {
        @defer {
          <app-personal-data-step
            email="{{ this.partnerFormModel.email }}"
            [personalFormError]="personalFormError"
            (onNextEvent)="submitPartnerData($event)"
          />
        }
      } @else if (activeStep === 3) {
        @defer {
          <app-final-step [partner]="partner" />
        }
      }
    </div>
  </div>
</div>