import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";

export const HttpInterceptor = (request:HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  return next(request).pipe(
    filter(event => event instanceof HttpResponse),
    map((event: HttpResponse<any>) => {
      const body = event.body;
      if (body?.errors?.length) {
        throw new HttpErrorResponse({
          error: new Error(body.errors[0].message),
          statusText: body.errors[0].message,
          status: body.errors[0].code,
        });
      } else {
        return event;
      }
    }),
    catchError((error: HttpErrorResponse) => {
      throw error;
    })
  );
};
