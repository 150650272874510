import { environment } from '@environments/environment';

export class LoggerService {

  static log(value: any, ...rest: any[]) {
    if (!environment.production) {
      console.log(value, ...rest);
    }
  }

  static error(error: any, ...rest: any[]) {
    console.error(error, ...rest);
  }

  static warn(value: any, ...rest: any[]) {
    if (!environment.production) {
      console.warn(value, ...rest);
    }
  }
}
