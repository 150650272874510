import { Injectable, inject } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private recaptchaV3Service = inject(ReCaptchaV3Service);

  generateToken(): Observable<string> {
    return this.recaptchaV3Service.execute('create_partner');
  }
}
